<template>
    <div class="hfull">
      <div class="header pt-lg-5">
        <b-container>
            <button class="btn btn-primary" @click="goBack"><i class="ni ni-bold-left"></i> KEMBALI</button>
        </b-container>
      </div>
  
    <b-container class="mt-5" >
      <b-row class="justify-content-center">
        <b-col>
          <b-card>
            <b-container>
                <div class="header-body text-center mb-7">
                <b-row class="justify-content-center">
                    <img
                    src="../../../../../public/img/brand/img-logo-color.png"
                    alt="klhk"
                    class="mb-2"
                    />
                    <b-col md="12">
                    <h2 class="text-black">
                        PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
                        MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
                        DAN/ATAU KEGIATAN
                    </h2>
                    <p class="text-lead text-black">
                        Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
                        validasi bagi penanggung jawab usaha dan/atau kegiatan yang
                        bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
                        Identification).
                    </p>
                    </b-col>
                </b-row>
                <button class="btn btn-primary" @click="showAnnoncementModal = true">
                    LIHAT MEKANISME
                </button>
                </div>
            </b-container>
            <h3 class="my-5 text-center">
              Pendaftaran khusus yang memanfaatkan air limbah
            </h3>
                <div class="p-5">
                  <div class="mb-5">
                    Khusus Industri Yang Menfaatkan Air Limbah 100% Ke Proses
                    Produksi
                  </div>
                  <div>

                    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                      <b-form role="form" @submit.prevent="handleSubmit(submitRegisterWaste)">
                        <!-- Debit Penggunaan air -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Penggunaan Air</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Penggunaan Air" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitPenggunaan.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Penggunaan Air" rules="required">
                              <el-select v-model="form.prosesProduksi.debitPenggunaan.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Penggunaan Air" placeholder="Keterangan (Proses dan Teknologi yang digunakan) " rules="required" v-model=" form.prosesProduksi.debitPenggunaan.keterangan"/>
                          </div>
                        </div>
    
    
                        <!-- Debit air limbah total -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Limbah Total</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Limbah Total" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitLimbahTotal.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Limbah Total" rules="required">
                              <el-select v-model="form.prosesProduksi.debitLimbahTotal.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Limbah Total" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitLimbahTotal.keterangan"/>
                          </div>
                        </div>
    
    
                        <!-- Debit air reuse -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Reuse</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Reuse" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitReuse.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Reuse" rules="required">
                              <el-select v-model="form.prosesProduksi.debitReuse.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Reuse" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitReuse.keterangan"/>
                          </div>
                        </div>
    
                        <!-- Debit air recycle -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Recycle</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Recycle" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitRecycle.value"></base-input>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Recycle" rules="required">
                              <el-select v-model="form.prosesProduksi.debitRecycle.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Recycle" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitRecycle.keterangan"/>
                          </div>
                        </div>
    
                        <!-- Debit air Recovery -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Recovery</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Recovery" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitRecovery.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Recovery" rules="required">
                              <el-select v-model="form.prosesProduksi.debitRecovery.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitRecovery.keterangan"/>
                          </div>
                        </div>
    
    
                        <!-- Debit air limbah diolah IPAL -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Debit Air Limbah Diolah IPAL</label>
                          <div class="col-lg-2">
                            <base-input name="Debit Air Limbah Diolah IPAL" placeholder="Masukan nilai debit" rules="required" v-model="form.prosesProduksi.debitOlahIPAL.value"/>
                          </div>
                          <div class="col-3">
                            <base-input name="Satuan Debit Air Limbah Diolah IPAL" rules="required">
                              <el-select v-model="form.prosesProduksi.debitOlahIPAL.satuan">
                                <el-option
                                  v-for="(unit, index) in debitUnit"
                                  :key="index"
                                  :label="unit"
                                  :value="unit"
                                />
                              </el-select>
                            </base-input>
                          </div>
                          <div class="col-lg">
                            <base-input name="Keterangan Debit Air Limbah Diolah IPAL" placeholder="Keterangan (Proses dan Teknologi yang digunakan)" rules="required" v-model="form.prosesProduksi.debitOlahIPAL.keterangan"
                            />
                          </div>
                        </div>
    
                        <!-- Unggah Neraca air dan unggaj air limbah -->
                        <div class="form-group row">
                          <label class="col-lg-2 col-form-label form-control-label">Unggah Neraca Air Dan Unggah Air Limbah</label>
                          <div class="col-lg-10">
                            <base-input  name="File Neraca Air Dan Unggah Air Limbah">
                              <file-input
                                accept="application/pdf"
                                ref="water_and_wastewater_balance_file"
                                @change="uploadFileBlob(file.pengolahan_kembali,'water_and_wastewater_balance_file')"
                              ></file-input>
                            </base-input>
                            <small v-if="file.pengolahan_kembali.water_and_wastewater_balance_file"
                                class="d-block mt-3 mb-2 text-justify">
                                <a :href="getBlobsFile(file.pengolahan_kembali.water_and_wastewater_balance_file)"
                                  target="_blank" class="rounded bg-primary p-2 text-white mt-2">Lihat file</a>
                            </small>
                          </div>
                        </div>
                        <div style="display: flex;justify-content: center;">
                          <button class="btn btn-primary w-50 mt-5" type="submit">KIRIM</button>
                        </div>
                      </b-form>
                    </validation-observer>

                  </div>
                </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    
    <Modal
      :show="showAnnoncementModal"
      modalContentClasses="announcement-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showAnnoncementModal = false"
      size="xl"
    >
      <template v-slot:header>
        <div class="mdl-header d-flex">
          <img
            src="../../../../../public/img/brand/img-logo-color.png"
            alt="klhk"
            class="mb-2"
          />
          <h3 class="ml-3">
            Kementerian <br />
            Lingkungan Hidup dan Kehutanan <br />
            Republik Indonesia
          </h3>
        </div>
      </template>
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contents">
          <div class="p-4" style="overflow-y: scroll; height: 450px">
            <h2 class="text-justify"></h2>
            <h2 class="text-justify">
              PENDAFTARAN SISTEM PEMANTAUAN KUALITAS AIR LIMBAH SECARA TERUS
              MENERUS DAN DALAM JARINGAN (SPARING) BAGI PENANGGUNG JAWAB USAHA
              DAN/ATAU KEGIATAN
            </h2>
            <p class="mb-2 text-justify">
              Pendaftaran adalah fasilitas untuk pencatatan secara ONLINE dan
              validasi bagi penanggung jawab usaha dan/atau kegiatan yang
              bertujuan untuk mendapatkan USER NAME, PASSWORD, UID (User
              Identification).
              <br />Mekanisme pendaftaran yaitu:
            </p>
            <ol>
              <li>
                Pendaftaran WAJIB dilakukan oleh penanggung jawab usaha dan/atau
                kegiatan (industri), tidak diperbolehkan bagi penyedia peralatan
                & jasa alat sensor pemantauan online.
              </li>
              <li>
                Penanggung jawab usaha dan/atau kegiatan WAJIB melaksanakan
                pendaftaran dan mengisi semua form serta bukti.
              </li>
              <li>
                Pengisian form pendaftaran dan bukti yaitu:
                <ol type="a">
                  <li>Pengisian umum</li>
                  <li>
                    Pengisian dan penyampaian bukti persyaratan identifikasi
                    sumber pencemar
                  </li>
                  <li>Pengisian dan penyampaian bukti persyaratan teknis</li>
                </ol>
              </li>
              <li>
                Hasil jawaban dari permohonan pendaftaran SPARING melalui fitur
                pendaftaran paling lambat dikirim 14 hari kerja setelah
                dikirimkan oleh penanggung jawab usaha dan/atau kegiatan.
              </li>
              <li>
                USER NAME, PASSWORD, UID (User Identification) berupa TANDA
                TERIMA ELEKTRONIK akan dikirimkan ke alamat email yang
                didaftarkan pada form pendaftaran melalui email
                sparing.menlhk@gmail.com jika seluruh isian lengkap dan
                dinyatakan valid oleh Admin SPARING KLHK.
              </li>
              <li>
                Bukti dan data dinyatakan valid melalui bukti persyaratan
                identifikasi sumber pencemar dan persyaratan teknis SPARING
                sesuai dengan Peraturan Menteri Lingkungan Hidup Nomor
                P.93/MENLHK/SETJEN/KUM.1/8/2018 tentang Pemantauan Kualitas Air
                Limbah Secara Terus Menerus Dan Dalam Jaringan Bagi Usaha
                Dan/Atau Kegiatan jo Peraturan Menteri Lingkungan Hidup Nomor
                P.80/MENLHK/SETJEN/KUM.1/10/2019.
              </li>
              <li>
                Jika isian tidak lengkap dan terdapat bukti yang tidak valid
                maka Admin SPARING KLHK akan menyampaikan melalui email yang
                terdaftar. Penanggung jawab usaha dan/atau kegiatan WAJIB
                melengkapi sesuai dengan hasil evaluasi.
              </li>
              <li>
                Kebijakan pengunduran waktu pemasangan dan pengoperasian SPARING
                sesuai Surat Edaran Menteri Lingkungan Hidup dan Kehutanan Nomor
                SE.5/Menlhk/Setjen/KUM.1/6/2020 tidak berlaku lagi.
              </li>
            </ol>

            <br />
            <span>
              Tim SPARING<br />
              Direktorat Pengendalian Pencemaran air<br />
              JL.DI Panjaitan Kav. 24 Kebon Nanas Jakarta Timur<br />
              Gedung B lantai 5<br />
              Email: sparing.menlhk@gmail.com<br />
              Fathia Rizki 0813 10837646 ( hanya melayani via pesan WA/SMS, jam
              pelayanan hari kerja 08.00-15.00 WIB)<br />
            </span>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="showAnnoncementModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>
     
    </div>
  </template>
  
  <script>
  import API from "@/api/base_url.js";
  import FileInput from "@/components/Inputs/FileInput";
  import "flatpickr/dist/flatpickr.css";
  import { Select, Option, Table, TableColumn } from "element-ui";
  import Modal from "@/components/Modal.vue";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  import PendaftaranSparingTable from "../../../Tables/PendaftaranSparing.vue"
  import BaseHeader from "@/components/BaseHeader";
  import baseURL from "@/api/base_url_backend.js";
  
  
  export default {
    name: "pendaftaranSparing",
    components: {
      BaseHeader,
      FileInput,
      [Select.name]: Select,
      [Option.name]: Option,
      Modal,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      PendaftaranSparingTable
    },
    mounted() {
    },
    data() {
      return {
        file: {
          pengolahan_kembali: {
            water_and_wastewater_balance_file: ''
          }
        },
        showFormRegisterWaste: false,
        showForm: false,
        showAnnoncementModal: false,
        form: {
          prosesProduksi: {
            debitPenggunaan: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitLimbahTotal: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitReuse: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitRecycle: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitRecovery: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitOlahIPAL: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            fileNeracaAirLimbah: {
              path: "",
              upload: 0,
              progress: 0,
            },
          },
        },
        formData: new FormData(),
        debitUnit: ['m3/detik','m3/menit','m3/jam','m3/hari','m3/bulan']
      };
    },
    methods: {
      // start upload
      uploadFileBlob(form, field) {
          const fileInput = this.$refs[field];
          const file = fileInput.files[0];

          const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
          if (!allowedTypes.includes(file.type)) {
            Swal.fire("Kesalahan", "Hanya file PDF dan gambar yang diizinkan", "error");
            return;
          }

          const maxSize = 5 * 1024 * 1024;
          if (file.size > maxSize) {
            Swal.fire("Kesalahan", "Ukuran file tidak boleh lebih dari 5MB", "error");
            return;
          }

          const formData = new FormData();
          formData.append("file", file);
          this.isLoading = true;
          API.post(`blobs/upload/public`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res) => {
              let data = res.data.data;
              let value = data.id;
              this.$set(form, field, value);
              this.isLoading = false;
            })
            .catch((err) => {
              this.isLoading = false;
              Swal.fire("Terjadi Kesalahan", "Mohon upload ulang kembali", "error");
            });
      },
      getBlobsFile(filename) {
        let url = `${baseURL}blobs/download/${filename}`
        return url
      },
      resetFileInput(ref) {
      if (this.$refs[ref]) {
        // this.$refs.lulus_uji_file_id.files = []
        this.$refs[ref].files = []
        const inputElement = this.$refs[ref].$el.querySelector('input[type="file"]');
        inputElement.value = ''
      }
      },
      resetAllFileInputs(refs) {
        refs.forEach(ref => this.resetFileInput(ref));
      },
      // end of upload
      goBack() {
        this.$router.push("/pengolahan-kembali/air-limbah");
      },
      close() {
        this.$router.push("/Home");
      },
      submitRegisterWaste() {
        Swal.fire({
          title: "Kirim Pemanfaatan air kembali?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "KIRIM",
          cancelButtonText: "BATAL"
        }).then((result) => {
          if (result.isConfirmed) {
            let payload = {
              attachment_file_id: this.file.pengolahan_kembali.water_and_wastewater_balance_file,
              debit_limbah_total: {
                desc: this.form.prosesProduksi.debitLimbahTotal.keterangan ,
                unit: this.form.prosesProduksi.debitLimbahTotal.satuan,
                value: +this.form.prosesProduksi.debitLimbahTotal.value
              },
              debit_olah_ipal: {
                desc: this.form.prosesProduksi.debitOlahIPAL.keterangan,
                unit: this.form.prosesProduksi.debitOlahIPAL.satuan,
                value: +this.form.prosesProduksi.debitOlahIPAL.value
              },
              debit_penggunaan: {
                desc: this.form.prosesProduksi.debitPenggunaan.keterangan,
                unit: this.form.prosesProduksi.debitPenggunaan.satuan,
                value: +this.form.prosesProduksi.debitPenggunaan.value
              },
              debit_recovery: {
                desc: this.form.prosesProduksi.debitRecovery.keterangan,
                unit: this.form.prosesProduksi.debitRecovery.satuan,
                value: +this.form.prosesProduksi.debitRecovery.value
              },
              debit_recycle: {
                desc: this.form.prosesProduksi.debitRecycle.keterangan,
                unit: this.form.prosesProduksi.debitRecycle.satuan ,
                value: +this.form.prosesProduksi.debitRecycle.value 
              },
              debit_reuse: {
                desc: this.form.prosesProduksi.debitReuse.keterangan,
                unit: this.form.prosesProduksi.debitReuse.satuan ,
                value: +this.form.prosesProduksi.debitReuse.value
              }
            }

            let headers = {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            }

            API.post(`waste-recycles`, payload, { headers })
              .then(({data:content}) => {
                Swal.fire('Data Berhasil didaftarkan', '', 'success')
                this.resetForm()
                this.$router.push("/pengolahan-kembali/air-limbah");
              })
              .catch((err) => {
                Swal.fire('Pendaftaran Gagal', 'gagal mendaftarkan', 'error')
              })
          }
        });
      },
      resetForm() {
        this.form = {
          prosesProduksi: {
            debitPenggunaan: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitLimbahTotal: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitReuse: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitRecycle: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitRecovery: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            debitOlahIPAL: {
              value: null,
              satuan: null,
              keterangan: null,
            },
            fileNeracaAirLimbah: {
              path: "",
              upload: 0,
              progress: 0,
            },
          },
        }
        this.formData = new FormData()
      }
    },
  };
  </script>

  